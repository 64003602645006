import { makeAutoObservable } from "mobx"
import languageRedirect from "./languageRedirect";

class Store {
    counter = 0;
    language='english';

    constructor() {
        let la
        if (typeof window !== `undefined`){
           la = window.location.pathname.split("/")?.filter(x=>x)[1];
        }
        
        if(la==='english'||la=='hindi'){this.language=la}
        makeAutoObservable(this)
    }

    increaseTimer() {
        this.counter += 1;
    }
    setLanguage(x){
        this.language=x;
        languageRedirect(x)
    }
}

export const store = new Store()
