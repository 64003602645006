import * as React from "react";
import "../assets/main.scss";
import footlogo from "../images/main-logo.svg";
import { ImOffice } from "@react-icons/all-files/im/ImOffice";
import { ImPhone } from "@react-icons/all-files/im/ImPhone";
import { AiOutlineMail } from "@react-icons/all-files/ai/AiOutlineMail";
import { FaBusinessTime } from "@react-icons/all-files/fa/FaBusinessTime";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import { StaticQuery, graphql, Link } from "gatsby";
import shape from "../assets/shapes/footer-shape.png";
import axios from "axios";
import { api } from "../config/api";

export default FooterPage;

function FooterPage({ cb }) {
  const [email, setEmail] = React.useState("");
  const [responseMsg, setResponseMsg] = React.useState("");

  const subscribe = () => {
    if (email) {
      axios
        .post(api.url + "newsletters", { email })
        .then((response) => {
          setResponseMsg("Thanks for subscribing");
        })
        .catch((res) => {
          setResponseMsg(
            "Failed to subscribe, " +
              res?.response?.data?.data?.errors?.email[0]
          );
        });
    } else setResponseMsg("Please fill your email");
  };

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const daa = data.allStrapiContactInfo.nodes[0];
        console.log(data);
        return (
          <div className="footer">
            <div className="subBox">
              <img src={shape} className="shape-buttonv3-max" />
              <div className="subscribe-w1">
                <p style={{ fontSize: 12, fontWeight: "normal" }}>NEWSLETTER</p>
                Subscribe our News Letter to get Latest Updates.
              </div>
              <div className="subscribe-w2">
                <input
                  className="whiteInput"
                  type="text"
                  value={email}
                  onChange={(input) => setEmail(input.target.value)}
                />
                <button onClick={subscribe} className="subscribeNowButton">
                  Subscribe Now
                </button>
                <p style={{ color: "white", fontWeight: 300, fontSize: 14 }}>
                  {responseMsg}
                </p>
              </div>
            </div>

            <div>
              <div className="mainfoot wrapper_max">
                <div>
                  <h2>{`Let's make something special`}</h2>
                  <h4>{`Let's talk! 🤙`}</h4>

                  <div className="contactbox contt">
                    <div>
                      <ImOffice className="contact-icon" />
                      <p>{daa.address}</p>
                    </div>
                    <div>
                      <ImPhone className="contact-icon" />
                      <p>{daa.phone}</p>
                    </div>
                    <div>
                      <AiOutlineMail className="contact-icon" />
                      <p>{daa.email}</p>
                    </div>
                    <div>
                      <FaBusinessTime className="contact-icon" />
                      <p>{daa.WorkingHours}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <h3>Useful Links</h3>
                  <div className="contactbox useful">
                    <div>
                      <FiChevronRight className="contact-icon" />
                      <Link
                        to="/about-us"
                        onClick={() => {
                          cb(true);
                        }}
                      >
                        <p>About Us</p>
                      </Link>
                    </div>
                    <div>
                      <FiChevronRight className="contact-icon" />
                      <Link
                        to="/contact-us"
                        onClick={() => {
                          cb(true);
                        }}
                      >
                        <p>Contact Us</p>
                      </Link>
                    </div>
                    <div>
                      <FiChevronRight className="contact-icon" />
                      <Link
                        to="/privacy-policy"
                        onClick={() => {
                          cb(true);
                        }}
                      >
                        <p>Privacy Policy</p>
                      </Link>
                    </div>
                    <div>
                      <FiChevronRight className="contact-icon" />
                      <Link
                        to="/terms-and-conditions"
                        onClick={() => {
                          cb(true);
                        }}
                      >
                        <p>Terms & Conditions</p>
                      </Link>
                    </div>
                    <div>
                      <FiChevronRight className="contact-icon" />
                      <Link
                        to="/cancellation-refund-policy"
                        onClick={() => {
                          cb(true);
                        }}
                      >
                        <p>Cancellation/Refund Policy</p>
                      </Link>
                    </div>
                  </div>
                </div>

                <div>
                  <h3>Our Services City</h3>
                  <div className="contactbox useful cities">
                    {data.strapiBooking.Locations.map((v, k) => (
                      <div key={k}>
                        <FiChevronRight className="contact-icon" />
                        <p>{v.city}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <h3>Download</h3>
                  <div className="contactbox useful">
                    <div>
                      <FiChevronRight className="contact-icon" />
                      <Link
                        to="/about-us"
                        onClick={() => {
                          cb(true);
                        }}
                      >
                        <p>About Us</p>
                      </Link>
                    </div>
                    <div>
                      <FiChevronRight className="contact-icon" />
                      <Link
                        to="/contact-us"
                        onClick={() => {
                          cb(true);
                        }}
                      >
                        <p>Contact Us</p>
                      </Link>
                    </div>
                    <div>
                      <FiChevronRight className="contact-icon" />
                      <Link
                        to="/privacy-policy"
                        onClick={() => {
                          cb(true);
                        }}
                      >
                        <p>Privacy Policy</p>
                      </Link>
                    </div>
                    <div>
                      <FiChevronRight className="contact-icon" />
                      <Link
                        to="/terms-and-conditions"
                        onClick={() => {
                          cb(true);
                        }}
                      >
                        <p>Terms & Conditions</p>
                      </Link>
                    </div>
                    <div>
                      <FiChevronRight className="contact-icon" />
                      <Link
                        to="/cancellation-refund-policy"
                        onClick={() => {
                          cb(true);
                        }}
                      >
                        <p>Cancellation/Refund Policy</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="lastFooter">
                <img src={footlogo} alt="" width={100} />
                <div>
                  <p style={{ fontSize: 12, fontWeight: "normal" }}>
                    ©2022 PESTGOGO
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}

const query = graphql`
  query FooterPageQuery {
    strapiGlobal {
      defaultSeo {
        metaDescription
      }
    }
    strapiBooking {
      Locations {
        city
      }
    }
    allStrapiContactInfo {
      nodes {
        address
        WorkingHours
        email
        phone
      }
    }
  }
`;

// export default FooterPage;
