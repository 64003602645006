import React from "react";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";
import Whatsapp from "./Whatsapp";
import SEO from "./SEO";
import "../assets/main.scss";

function Layout(props) {
  const [loader, setLoader] = React.useState(false);

  return loader ? (
    <div className="loaderBod">
      <div className="loader2"></div>
    </div>
  ) : (
    <>
      <SEO title={props.title} />
      <div className={`page${props.bodyClass ? ` ${props.bodyClass}` : ""}`}>
        <div id="wrapper" className="wrapper">
          <Header cb={setLoader} />
          {props.children}
        </div>
        <Footer cb={setLoader} />
        <Whatsapp />
        {/* <SubFooter /> */}
      </div>
    </>
  );
}

export default Layout;
