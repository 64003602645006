import React, { useState, useEffect } from "react";
import logo from "../images/main-logo-white.svg";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Image,
  Slide,
  Slider,
} from "pure-react-carousel";

import { Link, graphql, StaticQuery, navigate } from "gatsby";
import "pure-react-carousel/dist/react-carousel.es.css";

import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { BiMenu } from "@react-icons/all-files/bi/BiMenu";
import { IoMdClose } from "@react-icons/all-files/io/IoMdClose";

import { store } from "../store/store";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { api } from "../config/api";

const Dropdown = ({ dropdownItems }) => (
  <>
    {dropdownItems.map((v, k) => (
      <p key={k} style={{ marginBottom: 5 }}>
        <Link to={v.link}>{v.title}</Link>
      </p>
    ))}
  </>
);

const LanguageSelector = observer(() => (
  <p>
    <a
      href="#"
      onClick={() => store.setLanguage("english")}
      className={store.language == "english" ? "active" : ""}
    >
      English
    </a>
    <a
      href="#"
      onClick={() => store.setLanguage("hindi")}
      className={store.language == "hindi" ? "active" : ""}
    >
      हिन्दी
    </a>
  </p>
));

const Menu = () => <BiMenu size={40} />;

const Close = () => <IoMdClose size={40} />;

const ExpandMenu = observer(
  ({ close, setService, product, setProduct, cb }) => {
    let language = store.language === "english";
    return (
      <div className="exMenu">
        <Link
          to={store.language === "english" ? "/blog" : "/blog/hindi"}
          activeClassName="active"
          onClick={close}
        >
          {language ? "Blog" : "ब्लॉग"}
        </Link>

        <Link to={"/services"} activeClassName="active" onClick={close}>
          {"Service"}
        </Link>

        {/* <div
          //to={"/services"}
          onClick={() => {
            setService(!service);
            setProduct(false);
            close();
          }}
          activeClassName="active"
        >
          {language ? "Services" : "सेवाएं"}
        </div> */}

        <div
          to={"/products"}
          onClick={() => {
            setProduct(!product);
            setService(false);
            close();
          }}
          activeClassName="active"
        >
          {language ? "Products" : "उत्पाद"}
        </div>
        <Link to={"/case-study"} activeClassName="active" onClick={close}>
          {language ? "Case Study" : "कंसल्टेंसी"}
        </Link>

        <Link
          to={"/booking"}
          activeClassName="active"
          onClick={() => {
            close();
            cb(true);
          }}
        >
          Book Appointment
        </Link>

        <div className="moblanguage">
          <p>{language ? "Language" : "भाषा"}</p>
          <p
            onClick={() => store.setLanguage("english")}
            className={store.language == "english" ? "active" : ""}
          >
            English
          </p>
          <p
            onClick={() => store.setLanguage("hindi")}
            className={store.language == "hindi" ? "active" : ""}
          >
            हिन्दी
          </p>
        </div>
      </div>
    );
  }
);

const MenuDrawer = observer(({ total, loop, sub }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const link =
    store.language === "english" ? `/${sub}/` : `/${sub}/${store.language}/`;

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="slidepar">
      <CarouselProvider
        className="carousel"
        visibleSlides={
          total <= 8
            ? total <= 5
              ? width < 550
                ? 2
                : total
              : width < 550
              ? 2
              : width < 750
              ? 3
              : width > 1050
              ? 6
              : 5
            : width < 900
            ? width < 550
              ? 2
              : width < 750
              ? 3
              : width > 1050
              ? 6
              : 5
            : 8
        }
        totalSlides={total}
        //visibleSlides={total<8?total:8}
        //totalSlides={total}

        step={2}
        naturalSlideWidth={8}
        naturalSlideHeight={12.5}
        hasMasterSpinner={false}
        infinite
      >
        <Slider>
          {loop.map((v, k) => (
            <Link key={k} to={link + v.slug} index={k}>
              <Slide onClick={() => navigate(link + v.slug)}>
                <Image src={v.icon.localFile.publicURL} />
                <p>
                  {store.language === "hindi"
                    ? v.titleHindi || v.hindiTitle
                    : v.title}
                </p>
              </Slide>
            </Link>
          ))}
        </Slider>

        <ButtonBack className="back btt">
          <FiChevronLeft />
        </ButtonBack>
        <ButtonNext className="next btt">
          <FiChevronRight />
        </ButtonNext>
      </CarouselProvider>
    </div>
  );
});

const Navv = observer(
  ({ service, services, setService, product, setProduct, cb }) => {
    let language = store.language === "english";

    return (
      <div className="nav">
        <Link to={"/"} activeClassName="active">
          {language ? "Home" : "होम पेज"}
        </Link>
        <Link
          to={store.language === "english" ? "/blog" : "/blog/hindi"}
          activeClassName="active"
        >
          {language ? "Blog" : "ब्लॉग"}
        </Link>
        <div activeClassName="active" className="dropdown">
          {"Service"}
          <div className="drdw">
            <div className="hid" />
            <Dropdown
              dropdownItems={[
                { title: "Overview", link: "/services" },
                { title: "B2B", link: "/service/business-to-business/" },
                { title: "B2C", link: "/service/business-to-consumer/" },
                {
                  /* ...services.map((v, k) => {
                  return { title: v.title, link: v.slug };
                }), */
                },
              ]}
            />
          </div>
        </div>
        {/* <div
        style={{
          cursor: "pointer",
          borderBottom: service ? "4px solid red" : "",
        }}
        onClick={() => {
          setService(!service);
          setProduct(false);
        }}
        activeClassName="active"
      >
        {language ? "Services" : "सेवाएं"}
      </div> */}
        <div
          style={{
            cursor: "pointer",
            borderBottom: product ? "4px solid red" : "",
          }}
          onClick={() => {
            setProduct(!product);
            setService(false);
          }}
          activeClassName="active"
        >
          {language ? "Products" : "उत्पाद"}
        </div>
        <Link to={"/case-study"} activeClassName="active">
          {language ? "Case Study" : "कंसल्टेंसी"}
        </Link>
        <div className="dropdown" activeClassName="active">
          {language ? "Language" : "भाषा"}
          <div className="drdw">
            <div className="hid" />
            <LanguageSelector />
          </div>
        </div>

        <div
          className="bookS"
          onClick={() => {
            navigate("/booking");
            cb(true);
          }}
        >
          Book Appointment
        </div>
      </div>
    );
  }
);

{
  /* // const ComponentName = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre> */
}
function Header(props) {
  const [menu, setMenu] = useState(false);
  const [service, setService] = useState(false);
  const [product, setProduct] = useState(false);

  const [discount, getDiscount] = useState(undefined);

  useEffect(() => {
    axios
      .get(api.url + "discounts")
      .then((x) => {
        getDiscount(x.data);
      })
      .catch((x) => {
        console.error("failed to load discount API");
      });
  }, []);

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const services = data.allStrapiServices.nodes;
        const products = data.allStrapiProducts.nodes;

        return (
          <div style={{ marginTop: 80 }}>
            <div className="headerP">
              <div className="header">
                <Link to={"/"}>
                  <img src={logo} alt="pestgogo best pest service in india" />
                </Link>

                <Navv
                  cb={props.cb}
                  service={service}
                  services={services}
                  setService={setService}
                  product={product}
                  setProduct={setProduct}
                />

                <div
                  className="menu"
                  onClick={() => {
                    setMenu(!menu);
                    setService(false);
                    setProduct(false);
                  }}
                >
                  {!menu ? <Menu /> : <Close />}
                </div>
              </div>

              {menu && (
                <ExpandMenu
                  cb={props.cb}
                  service={service}
                  setService={setService}
                  product={product}
                  setProduct={setProduct}
                  close={() => setMenu(false)}
                />
              )}

              {service && (
                <MenuDrawer
                  total={data.allStrapiServices.totalCount}
                  loop={services}
                  sub="services"
                />
              )}
              {product && (
                <MenuDrawer
                  total={data.allStrapiProducts.totalCount}
                  loop={products}
                  sub="products"
                />
              )}
              {discount && discount?.name != "#N/A" && (
                <marquee className="discountHeader">
                  {discount.name} {discount.comment && `|| ${discount.comment}`}{" "}
                </marquee>
              )}
            </div>
          </div>
        );
      }}
    />
  );
}

const query = graphql`
  {
    allStrapiServices {
      nodes {
        title
        titleHindi
        slug
        icon {
          localFile {
            publicURL
          }
        }
      }
      totalCount
    }

    allStrapiProducts {
      nodes {
        title
        hindiTitle
        slug
        icon {
          localFile {
            publicURL
          }
        }
      }
      totalCount
    }
  }
`;
export default Header;
