import { navigate } from "gatsby";

export default function languageRedirect(x) {
  let url;
  if (typeof window !== `undefined`) {
    url = window.location;
  }
  const arr = url?.pathname.split("/").filter((x) => x);
  console.log(arr);

  if (arr.length === 1 && arr.includes("blog") && x === "hindi")
    navigate("/blog/hindi");
  if (arr.length === 2 && arr.includes("blog") && x === "english")
    navigate("/blog");

  if (arr.length === 2 && arr.includes("services") && x === "hindi")
    navigate("/services/hindi/" + arr[1]);

  if (arr.length === 3 && arr.includes("services") && x === "english")
    navigate("/services/" + arr[2]);

  if (arr.length === 2 && arr.includes("products") && x === "hindi")
    navigate("/products/hindi/" + arr[1]);

  if (arr.length === 3 && arr.includes("products") && x === "english")
    navigate("/products/" + arr[2]);

  if (arr.length === 2 && arr.includes("blog") && x === "hindi")
    navigate("/blog/hindi/" + arr[1]);

  if (arr.length === 3 && arr.includes("blog") && x === "english")
    navigate("/blog/" + arr[2]);
}
